<template>
    <div class="container-fluid">
        <img v-show="imageToLoad === 'alert'" src="@assets/images/app/alert.png" width="100%" class="m-0" />
        <img v-show="imageToLoad === 'evacuate'" src="@assets/images/app/evacuate.png" width="100%" class="m-0" />
        <div class="row align-items-center my-2">
            <div class="col-9">
                <h4 v-if="procedure" class="m-1 float-left mr-2">{{ procedure.displayCategory }}</h4>
                <h4 v-else class="m-1 float-left mr-2">Select Category</h4>
            </div>
            <div class="col-3">
                <b-button class="pull-right" size="sm" v-b-toggle.collapse-1>Change</b-button>
            </div>
        </div>
        <b-collapse id="collapse-1" class="mt-2">
            <div class="row mb-2">
                <div class="col-12">
                    <b-list-group>
                        <b-list-group-item v-for="(item, i) in categories" :key="i"
                            :href="bindRouteToProcedure(item.id)" :active="isCategoryActive(item.id)">{{
                                item.name
                            }}</b-list-group-item>
                    </b-list-group>
                </div>
            </div>
        </b-collapse>
        <div class="card" v-if="procedure">
            <div class="card-body">
                <div v-html="procedure.procedureText"></div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    externalData
} from '@state/api/pwaexternal';

export default {
    data() {
        return {
            procedure: undefined,
            designationId: null,
            categories: [],
            imageToLoad: "alert",
            currentTimer: 0
        }
    },
    computed: {
        locationId() {
            return this.$route.params.locationId;
        },
    },
    async created() {
        this.currentTimer = setTimeout(this.changeToArea, 30000);
        await this.loadProcedure();
        await this.loadCategories();
    },
    destroyed() {
        clearTimeout(this.currentTimer);
    },
    methods: {
        bindRouteToProcedure(categoryId) {
            return `/${this.locationId}/designation/${this.designationId}/selectedCategory/${categoryId}`
        },
        changeToArea() {
            this.imageToLoad = "evacuate";
            clearTimeout(this.currentTimer);
            this.$router.push(`/openapp/${this.locationId}/default-assemblyarea`).catch(() => {});
        },
        isCategoryActive(categoryId) {
            if (categoryId == this.procedure.category) {
                return true
            }
            return false
        },
        async loadCategories() {
            this.categories = await externalData.searchCategory(this.locationId);
        },
        async loadProcedure() {
            this.procedure = await externalData.searchProcedureWithDefault(this.locationId);
            if (!this.procedure) {
                this.$vs.notify({
                    title: "Error",
                    text: "No Procedure found, please contract administrator",
                    color: "danger",
                    position: "top"
                });
            }

            this.designationId = this.procedure.procedureFor
        },
    }
}
</script>